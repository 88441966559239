<template>
  <b-row>
    <b-col cols="12">
      <p class="primary fs-24 fw-bold py-2">Levantamiento de Prenda</p>
      <template v-if="!showform">
        <p class="fw-bold">Solicita tu levantamiento de prenda SOLO si cumples los siguientes requisitos:</p>
        <p class="p-0 m-0 text-justify">
          <span class="italic underline">Si tu motocicleta es Honda:</span>
          La pignoración de la tarjeta de propiedad debe estar bajo el nombre de: Banco de Occidente, Giros & Finanzas,
          ADEINCO S.A. o Administración e Inversiones Comerciales S.A.
        </p>
        <p class="p-0 m-0 text-justify">
          <span class="italic underline">Si tu moto es de otra marca:</span>
          La pignoración debe estar validada solo por el nombre de ADEINCO S.A. o Administración e Inversiones
          Comerciales S.A.
        </p>
        <p class="p-0 m-0 text-justify">
          <span class="fw-bold">Si cumple con lo anterior</span>, consigna <span class="fw-bold danger">$30.000</span>
          en Bancolombia al convenio 14761 a nombre de ADEINCO PRENDA. registra el
          <span class="fw-bold">nombre y la cédula de quién aparece en la tarjeta de propiedad.</span>
        </p>
        <p class="p-0 mt-3 mb-3 text-justify danger fw-bold">
          RECUERDA: Tu levantamiento de prenda NO podrá ser entregado en VEREDAS o ZONAS RURALES registra una dirección
          de entrega ubicada en la CIUDAD PRINCIPAL más cercana
        </p>
        <b-row class="mt-3 mb-5">
          <b-col cols="12" md="9" class="content-center">
            <b-form-checkbox v-model="readRequisitos">
              He leído los requisitos de levantamiento de prenda
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" md="3" class="d-flex justify-content-end">
            <b-button variant="danger" :disabled="!readRequisitos" @click="aceptarRequisitos">
              <i class="las la-check-circle" /> Aceptar
            </b-button>
          </b-col>
        </b-row>
      </template>
      <template v-else>
        <b-row>
          <FormUsuario ref="formUsuario" @updateForm="updateForm" />
          <b-col cols="12" class="pt-2">
            <p class="fw-bold primary">Dirección envío levantamiento de prendas</p>
            <p class="fs-14 italic fw-bold">
              El levantamiento de prenda se enviará físico por correo única vez a la dirección seleccionada. Si ocurre
              alguna novedad en el proceso de entrega, el cliente asume los costos de los envíos posteriores.
            </p>
            <div class="alert alert-info text-center">
              Te informamos que tu levantamiento de prenda <span class="fw-bold">NO</span> podrá ser entregado en
              <span class="fw-bold">VEREDAS O ZONAS RURALES</span>, registra una dirección de entrega ubicada en la
              <span class="fw-bold">CIUDAD PRINCIPAL</span> más cercana.
            </div>
          </b-col>
          <FormDireccion ref="formDireccion" @updateForm="updateForm" />
          <b-col cols="12" class="pt-2">
            <p class="fw-bold primary">Información del propietario</p>
            <p class="fs-14 italic fw-bold">
              Recuerda que debes incluir el nombre de la persona que registra en la tarjeta de propiedad de la
              motocicleta.
            </p>
          </b-col>
          <FormPropietario ref="formPropietario" @updateForm="updateForm" />
          <b-col>
            <p class="fw-bold italic">* Campos Obligatorios</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="content-end-center pb-5">
            <b-button variant="danger" @click="sendForm"><i class="las la-check-circle" /> Solicitar</b-button>
          </b-col>
        </b-row>
        <ModalLevantamientoPrenda :modal="modal" :data="formLP" @useModal="useModal" />
        <ModalValidarOtp
          :tiempoReenvioCodigo="tiempoHabilitarReenvio"
          :digitosCelular="digitosCelular"
          @reenviarCodigo="reenviarCodigo"
          @sendForm="validarCodigo"
          @useModal="useModalOtp"
          :modal="modalOtp"
          ref="modalOtp"
        />
      </template>
    </b-col>
  </b-row>
</template>

<script>
import ComunesService from "@/app/core/api/comunes";
import CarteraService from "@/app/core/api/cartera";
import mixinsEvents from "@/app/shared/mixins/mixins-events";
import AlertsService from "@/app/shared/services/alerts.service";

// COMPONENTS
import FormUsuario from "../components/FormUsuario.vue";
import FormDireccion from "../components/FormDireccion.vue";
import FormPropietario from "../components/FormPropietario.vue";
import ModalValidarOtp from "@/app/shared/components/ModalValidarOtp.vue";
import ModalLevantamientoPrenda from "../components/ModalLevantamientoPrenda.vue";

export default {
  mixins: [mixinsEvents],
  components: { FormUsuario, FormDireccion, FormPropietario, ModalLevantamientoPrenda, ModalValidarOtp },
  props: { esLevPrendaWeb: { type: Boolean, default: false } },
  data() {
    return {
      showform: false,
      readRequisitos: false,
      modal: { isOpen: false },
      modalOtp: { isOpen: false },
      tiempoHabilitarReenvio: null,
      info: { numeroCelular: null },
      formLP: {
        estado: "1",
        consecutivo: 0,
        fecha: new Date(),
        usuario: "usuSuc",
        origen: "SUCURSAL",
        direccionEntrega: "-",
      },
    };
  },
  watch: {
    showform(val) {
      if (val) {
        this.generarCodigo();
        this.digitosCelular = this.$store.getters.numeroCelular.slice(-4);
        this.useModalOtp();
      }
    },
  },
  created() {
    if (!this.$store.getters.isGetcreditosLevPrenda || this.esLevPrendaWeb) this.getCreditosLevPrenda();
    else {
      if (this.$store.getters?.creditosLevPrenda.length === 0) {
        this.$router.push("/home");
        AlertsService.error("Levantamiento de Prenda", "Actualmente no tienes créditos cancelados.");
      }
    }
  },
  methods: {
    getCreditosLevPrenda() {
      const body = { tipoDoc: this.$store.getters.tipoDocumento, cedula: this.$store.getters.identificacion };

      CarteraService.getCreditosLevPrenda(body).then((response) => {
        const data = response.data.listaCreditos;
        this.$store.commit("setCreditosLevPrenda", data);
        this.$store.commit("setIsGetCreditosLevPrenda", true);
        if (data.length === 0) {
          if (!this.esLevPrendaWeb) this.$router.push("/home");
          else this.$router.push("/validar-lev-prenda-web");

          AlertsService.error("Levantamiento de Prenda", "Actualmente no tienes créditos cancelados.");
        }
      });
    },
    aceptarRequisitos() {
      this.showform = !this.showform;
    },
    updateForm(data) {
      this.formLP = { ...this.formLP, ...data };
    },
    useModal() {
      this.modal.isOpen = !this.modal.isOpen;
    },
    sendForm() {
      this.$refs.formUsuario.$v.$touch();
      this.$refs.formDireccion.$v.$touch();
      this.$refs.formPropietario.$v.$touch();

      if (
        !this.$refs.formUsuario.$v.$invalid &&
        !this.$refs.formDireccion.$v.$invalid &&
        !this.$refs.formPropietario.$v.$invalid
      ) {
        this.formLP = {
          ...this.formLP,
          puntoReclamo: this.getPuntoReclamo(),
          ciudadTitular: this.formLP.ciudadTitular?.value,
          ciudadReclamo: this.formLP.ciudadTitular?.value,
          direccionTipo: this.formLP.direccionTipo?.value,
          direccionSector: this.formLP.direccionSector?.value,
          departamentoTitular: this.formLP.departamentoTitular?.value,
          celularTitular: this.formatCelular(this.formLP.celularTitular),
          direccionCorregimiento: this.formLP.direccionCorregimiento?.value,
        };

        CarteraService.procesarLevPrenda(this.formLP).then((response) => {
          if (response.data.success) this.useModal();
        });
      } else AlertsService.error("Levantamiento de Prenda", "Completa todos los campos obligatorios.");
    },
    getPuntoReclamo() {
      return `${this.formLP.ciudadTitular?.text} - ${this.formLP.direccionTipo?.text} ${
        this.formLP.direccionNumero
      } ${this.validarVacio(this.formLP.direccionSufijoNumero)} ${this.validarVacio(
        this.formLP.direccionSector?.text
      )} ${this.formLP.direccionNumero1} ${this.validarVacio(this.formLP.direccionSufijoDireccion)} ${
        this.formLP.direccionNumero2
      } ${this.formLP.direccionBarrio !== null ? `| BARRIO: ${this.formLP.direccionBarrio}` : ""} | CORREGIMIENTO: ${
        this.formLP.direccionCorregimiento?.text
      } ${
        this.formLP.direccionInfoDomicilio !== null ? `| CARACTERISTICAS: ${this.formLP.direccionInfoDomicilio}` : ""
      }`.toUpperCase();
    },
    useModalOtp() {
      if (this.modalOtp.isOpen) {
        this.showform = false;
        this.readRequisitos = false;
      }
      this.modalOtp.isOpen = !this.modalOtp.isOpen;
    },
    generarCodigo() {
      const body = { cedula: this.$store.getters.identificacion, idSolicitud: 0, estado: 0, tipo: "2" };

      ComunesService.generarCodigoLevantamientoPrenda(body)
        .then((response) => {
          if (response.data.estado) {
            this.tiempoHabilitarReenvio = response.data.tiempoHabilitarReenvio;
            setTimeout(() => this.$refs.modalOtp.iniciarContador(), 0);
            AlertsService.success("Levantamiento de Prenda", response.data.mensaje);
          }
        })
        .catch(() => {
          this.$router.push("/home");
        });
    },
    validarCodigo(codigo) {
      const body = { cedula: this.$store.getters.identificacion, codigoOTP: codigo };

      ComunesService.validarCodigoLevantamientoPrenda(body).then((response) => {
        AlertsService[response.data.estado ? "success" : "error"]("Validación Código OTP", response.data.mensaje);
        if (response.data.estado) {
          this.modalOtp.isOpen = false;
        }
        if (response.data.cerrarModal) this.$router.push("/home");
      });
    },
    reenviarCodigo() {
      const body = { cedula: this.$store.getters.identificacion, idSolicitud: 0, estado: 1, tipo: "1" };

      ComunesService.reenviarCodigoLevantamientoPrenda(body).then((response) => {
        if (response.data.estado) {
          this.tiempoHabilitarReenvio = response.data.tiempoHabilitarReenvio;
          setTimeout(() => this.$refs.modalOtp.iniciarContador(), 0);
          AlertsService.success("Levantamiento de Prenda", response.data.mensaje);
        }
      });
    },
  },
};
</script>
