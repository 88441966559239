<template>
  <b-col cols="12">
    <b-row>
      <b-col sm="12" lg="6">
        <b-form-group label="Nombre propietario *">
          <b-form-input
            @keyup="upperCase"
            v-model="$v.formP.nombrePropietario.$model"
            :state="$v.formP.nombrePropietario.$dirty ? !$v.formP.nombrePropietario.$error : null"
          />
          <b-form-invalid-feedback>{{ validarCampo($v.formP.nombrePropietario) }}</b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col sm="12" lg="6">
        <b-form-group label="Cédula propietario *">
          <b-form-input
            v-model="$v.formP.identificacionPropietario.$model"
            :state="$v.formP.identificacionPropietario.$dirty ? !$v.formP.identificacionPropietario.$error : null"
          />
          <b-form-invalid-feedback>{{ validarCampo($v.formP.identificacionPropietario) }}</b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col sm="12" lg="6">
        <b-form-group label="Placa *">
          <b-form-input
            @keyup="upperCase"
            v-model="$v.formP.placa.$model"
            :state="$v.formP.placa.$dirty ? !$v.formP.placa.$error : null"
          />
          <b-form-invalid-feedback>{{ validarCampo($v.formP.placa) }}</b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col sm="12" lg="6">
        <div role="group">
          <label for="referenciaPago" class="primary">
            Referencia de pago * <i @click="useModal" class="la la-question-circle la-lg" />
          </label>
          <b-form-input
            id="referenciaPago"
            @keypress="onlyNumber"
            v-model="$v.formP.referenciaPago.$model"
            :state="$v.formP.referenciaPago.$dirty ? !$v.formP.referenciaPago.$error : null"
          />
          <b-form-invalid-feedback>{{ validarCampo($v.formP.referenciaPago) }}</b-form-invalid-feedback>
        </div>
      </b-col>
    </b-row>
    <ModalReferenciasPago :modal="modal" @useModal="useModal" />
  </b-col>
</template>

<script>
import mixinsEvents from "@/app/shared/mixins/mixins-events";
import { required, numeric, helpers, alphaNum, minLength, maxLength } from "vuelidate/lib/validators";

// COMPONENTS
import ModalReferenciasPago from "./ModalReferenciasPago.vue";

const alpha = helpers.regex("alpha", /^[a-zA-ZñÑ\s]*$/);

export default {
  mixins: [mixinsEvents],
  components: { ModalReferenciasPago },
  data() {
    return {
      modal: { isOpen: false },
      formP: { placa: null, referenciaPago: null, nombrePropietario: null, identificacionPropietario: null },
    };
  },
  watch: {
    formP: {
      handler: function() {
        this.$emit("updateForm", this.formP);
      },
      deep: true,
    },
  },
  validations: {
    formP: {
      referenciaPago: { required, numeric },
      nombrePropietario: { required, alpha },
      identificacionPropietario: { required, numeric },
      placa: { required, alphaNum, minLength: minLength(6), maxLength: maxLength(6) },
    },
  },
  methods: {
    useModal() {
      this.modal.isOpen = !this.modal.isOpen;
    },
  },
};
</script>
