var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { size: "lg", centered: "", "hide-footer": "" },
      on: {
        close: function ($event) {
          return _vm.onCloseModal()
        },
      },
      scopedSlots: _vm._u([
        {
          key: "modal-title",
          fn: function () {
            return [_vm._v(" Levantamiento de Prenda ")]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.modal.isOpen,
        callback: function ($$v) {
          _vm.$set(_vm.modal, "isOpen", $$v)
        },
        expression: "modal.isOpen",
      },
    },
    [
      _c(
        "b-row",
        { staticClass: "px-4 py-2" },
        [
          _c("b-col", { attrs: { sm: "12" } }, [
            _c("p", { staticClass: "primary" }, [
              _vm._v(
                "Hola, " +
                  _vm._s(
                    _vm.data.nombresTitular + " " + _vm.data.apellidosTitular
                  )
              ),
            ]),
            _c("p", { staticClass: "primary text-justify" }, [
              _vm._v(
                " Confirmamos que hemos recibido tu solicitud, una vez enviemos el levantamiento de prenda de tu motocicleta, recibiras un correo electrónico a tu email registrado con nosotros " +
                  _vm._s(_vm.data.emailTitular) +
                  " y un mensaje de texto al número de celular " +
                  _vm._s(_vm.data.celularTitular) +
                  ", con el número de guía para que realices el seguimiento del envío a traves de la pagina web de la transportadora. "
              ),
            ]),
            _c("p", { staticClass: "primary text-justify" }, [
              _vm._v(
                " Recuerda que tu levantamiento de prenda será enviado a la dirección "
              ),
              _c("strong", [_vm._v(_vm._s(_vm.data.puntoReclamo))]),
              _vm._v(" dentro de los proximos 15 días habiles. "),
            ]),
            _c("p", { staticClass: "primary text-justify" }, [
              _vm._v(
                " Si aún no has realizado el pago correspondiente al costo de este tramite, recuerda hacerlo consignando $30.000 pesos en Bancolombia al convenio 14761. Tendrás 24 horas para realizar el pago, de lo contrario tu solicitud será cancelada y deberás realizarla de nuevo. "
              ),
            ]),
            _c("p", { staticClass: "primary mb-2" }, [
              _c("strong", [
                _vm._v("Líneas de atención al cliente ProgreSER:"),
              ]),
            ]),
            _c(
              "ul",
              { staticClass: "primary pl-4" },
              _vm._l(_vm.telefonos, function (tel, idx) {
                return _c("li", { key: idx }, [
                  _c("strong", [_vm._v(_vm._s(tel))]),
                ])
              }),
              0
            ),
          ]),
          _c(
            "b-col",
            { staticClass: "content-center", attrs: { cols: "12" } },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "danger" },
                  on: {
                    click: function ($event) {
                      return _vm.terminarProceso()
                    },
                  },
                },
                [_vm._v("Aceptar")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }