<template>
  <b-col cols="12">
    <b-form-row>
      <b-col cols="12" md="6" lg="3">
        <b-form-group label="Departamento *">
          <b-form-select
            :options="list.dptos"
            @change="getCiudadesByDpto"
            v-model="$v.formD.departamentoTitular.$model"
            :state="$v.formD.departamentoTitular.$dirty ? !$v.formD.departamentoTitular.$error : null"
          >
            <template #first>
              <b-form-select-option :value="null">Seleccione</b-form-select-option>
            </template>
          </b-form-select>
          <b-form-invalid-feedback>{{ validarCampo($v.formD.departamentoTitular) }}</b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="3">
        <b-form-group label="Municipio *">
          <b-form-select
            :options="list.ciudades"
            @change="getCorregimientosByCiudad"
            v-model="$v.formD.ciudadTitular.$model"
            :state="$v.formD.ciudadTitular.$dirty ? !$v.formD.ciudadTitular.$error : null"
          >
            <template #first>
              <b-form-select-option :value="null">Seleccione</b-form-select-option>
            </template>
          </b-form-select>
          <b-form-invalid-feedback>{{ validarCampo($v.formD.ciudadTitular) }}</b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="3">
        <b-form-group label="Corregimiento *">
          <b-form-select
            :options="list.corregimientos"
            v-model="$v.formD.direccionCorregimiento.$model"
            :state="$v.formD.direccionCorregimiento.$dirty ? !$v.formD.direccionCorregimiento.$error : null"
          >
            <template #first>
              <b-form-select-option :value="null">Seleccione</b-form-select-option>
            </template>
          </b-form-select>
          <b-form-invalid-feedback>{{ validarCampo($v.formD.direccionCorregimiento) }}</b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="3">
        <b-form-group label="Barrio">
          <b-form-input
            v-model="$v.formD.direccionBarrio.$model"
            :state="$v.formD.direccionBarrio.$dirty ? !$v.formD.direccionBarrio.$error : null"
          />
          <b-form-invalid-feedback>{{ validarCampo($v.formD.direccionBarrio) }}</b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="3">
        <b-form-group label="Vía principal *">
          <b-form-select
            :options="list.tiposDireccion"
            v-model="$v.formD.direccionTipo.$model"
            :state="$v.formD.direccionTipo.$dirty ? !$v.formD.direccionTipo.$error : null"
          >
            <template #first>
              <b-form-select-option :value="null">Seleccione</b-form-select-option>
            </template>
          </b-form-select>
          <b-form-invalid-feedback>{{ validarCampo($v.formD.direccionTipo) }}</b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="3">
        <b-form-group label="Número *">
          <b-form-input
            @keypress="onlyNumber"
            v-model="$v.formD.direccionNumero.$model"
            :state="$v.formD.direccionNumero.$dirty ? !$v.formD.direccionNumero.$error : null"
          />
          <b-form-invalid-feedback>{{ validarCampo($v.formD.direccionNumero) }}</b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="3">
        <b-form-group label="Letra/Número">
          <b-form-input
            @keyup="upperCase"
            v-model="$v.formD.direccionSufijoNumero.$model"
            :state="$v.formD.direccionSufijoNumero.$dirty ? !$v.formD.direccionSufijoNumero.$error : null"
          />
          <b-form-invalid-feedback>{{ validarCampo($v.formD.direccionSufijoNumero) }}</b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="3">
        <b-form-group label="Sector">
          <b-form-select
            :options="list.sectores"
            v-model="$v.formD.direccionSector.$model"
            :state="$v.formD.direccionSector.$dirty ? !$v.formD.direccionSector.$error : null"
          >
            <template #first>
              <b-form-select-option :value="null">Seleccione</b-form-select-option>
            </template>
          </b-form-select>
          <b-form-invalid-feedback>{{ validarCampo($v.formD.direccionSector) }}</b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="3">
        <b-form-group label="Número *">
          <b-form-input
            @keypress="onlyNumber"
            v-model="$v.formD.direccionNumero1.$model"
            :state="$v.formD.direccionNumero1.$dirty ? !$v.formD.direccionNumero1.$error : null"
          />
          <b-form-invalid-feedback>{{ validarCampo($v.formD.direccionNumero1) }}</b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="3">
        <b-form-group label="Letra/Número">
          <b-form-input
            @keyup="upperCase"
            v-model="$v.formD.direccionSufijoDireccion.$model"
            :state="$v.formD.direccionSufijoDireccion.$dirty ? !$v.formD.direccionSufijoDireccion.$error : null"
          />
          <b-form-invalid-feedback>{{ validarCampo($v.formD.direccionSufijoDireccion) }}</b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="3">
        <b-form-group label="Número *">
          <b-form-input
            @keypress="onlyNumber"
            v-model="$v.formD.direccionNumero2.$model"
            :state="$v.formD.direccionNumero2.$dirty ? !$v.formD.direccionNumero2.$error : null"
          />
          <b-form-invalid-feedback>{{ validarCampo($v.formD.direccionNumero2) }}</b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="3">
        <b-form-group label="Otras características">
          <b-form-input
            v-model="$v.formD.direccionInfoDomicilio.$model"
            :state="$v.formD.direccionInfoDomicilio.$dirty ? !$v.formD.direccionInfoDomicilio.$error : null"
          />
          <b-form-invalid-feedback>{{ validarCampo($v.formD.direccionInfoDomicilio) }}</b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
  </b-col>
</template>

<script>
import ComunesService from "@/app/core/api/comunes";
import mixinsEvents from "@/app/shared/mixins/mixins-events";
import { required, numeric, helpers } from "vuelidate/lib/validators";

const alphaNum = helpers.regex("alphaNum", /^[a-zA-Z0-9\s]*$/);

export default {
  mixins: [mixinsEvents],
  data() {
    return {
      valueNoAplica: { text: "NO APLICA", value: { text: "N/A", value: "N/A" } },
      list: { dptos: [], ciudades: [], sectores: [], tiposDireccion: [], corregimientos: [] },
      formD: {
        ciudadTitular: null,
        direccionTipo: null,
        direccionBarrio: null,
        direccionNumero: null,
        direccionSector: null,
        direccionNumero1: null,
        direccionNumero2: null,
        departamentoTitular: null,
        direccionSufijoNumero: null,
        direccionCorregimiento: null,
        direccionInfoDomicilio: null,
        direccionSufijoDireccion: null,
      },
    };
  },
  watch: {
    formD: {
      handler: function() {
        this.$emit("updateForm", this.formD);
      },
      deep: true,
    },
  },
  validations: {
    formD: {
      direccionSector: {},
      direccionBarrio: {},
      direccionInfoDomicilio: {},
      direccionTipo: { required },
      ciudadTitular: { required },
      departamentoTitular: { required },
      direccionSufijoNumero: { alphaNum },
      direccionCorregimiento: { required },
      direccionSufijoDireccion: { alphaNum },
      direccionNumero: { required, numeric },
      direccionNumero1: { required, numeric },
      direccionNumero2: { required, numeric },
    },
  },
  created() {
    this.getListas();
  },
  methods: {
    getListas() {
      this.list.corregimientos = [this.valueNoAplica];
      ComunesService.getDepartamentos().then((response) => {
        this.list.dptos = response.data.map((item) => ({
          value: { text: item.nombre, value: item.codigo },
          text: item.nombre,
        }));
      });
      ComunesService.getTiposDireccion().then((response) => {
        this.list.sectores = response.data.sectores.map((item) => ({ text: item.text, value: item }));
        this.list.tiposDireccion = response.data.tiposDireccion.map((item) => ({ text: item.text, value: item }));
      });
    },
    getCiudadesByDpto(data) {
      this.formD = { ...this.formD, ciudadTitular: null, direccionCorregimiento: null };
      this.list = { ...this.list, ciudades: [], corregimientos: [this.valueNoAplica] };
      ComunesService.getCiudadesByDpto(data.value).then((response) => {
        this.list.ciudades = response.data.map((item) => ({
          value: { text: item.nombre, value: item.codigo },
          text: item.nombre,
        }));
      });
    },
    getCorregimientosByCiudad(data) {
      this.formD = { ...this.formD, direccionCorregimiento: null };
      this.list = { ...this.list, corregimientos: [this.valueNoAplica] };
      ComunesService.getCorregimientosByCiudad(data.value).then((response) => {
        response.data.map((item) => {
          this.list.corregimientos.push({
            value: { text: item.nombre, value: item.codigo },
            text: item.nombre,
          });
        });
      });
    },
  },
};
</script>
