<template>
  <b-modal size="lg" centered hide-footer v-model="modal.isOpen" @close="onCloseModal()">
    <template #modal-title>
      Levantamiento de Prenda
    </template>
    <b-row class="px-4 py-2">
      <b-col sm="12">
        <p class="primary">Hola, {{ `${data.nombresTitular} ${data.apellidosTitular}` }}</p>
        <p class="primary text-justify">
          Confirmamos que hemos recibido tu solicitud, una vez enviemos el levantamiento de prenda de tu motocicleta,
          recibiras un correo electrónico a tu email registrado con nosotros {{ data.emailTitular }} y un mensaje de
          texto al número de celular {{ data.celularTitular }}, con el número de guía para que realices el seguimiento
          del envío a traves de la pagina web de la transportadora.
        </p>
        <p class="primary text-justify">
          Recuerda que tu levantamiento de prenda será enviado a la dirección
          <strong>{{ data.puntoReclamo }}</strong> dentro de los proximos 15 días habiles.
        </p>
        <p class="primary text-justify">
          Si aún no has realizado el pago correspondiente al costo de este tramite, recuerda hacerlo consignando $30.000
          pesos en Bancolombia al convenio 14761. Tendrás 24 horas para realizar el pago, de lo contrario tu solicitud
          será cancelada y deberás realizarla de nuevo.
        </p>
        <p class="primary mb-2">
          <strong>Líneas de atención al cliente ProgreSER:</strong>
        </p>
        <ul class="primary pl-4">
          <li :key="idx" v-for="(tel, idx) in telefonos">
            <strong>{{ tel }}</strong>
          </li>
        </ul>
      </b-col>
      <b-col cols="12" class="content-center">
        <b-button variant="danger" @click="terminarProceso()">Aceptar</b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import mixinsEvents from "@/app/shared/mixins/mixins-events";

export default {
  mixins: [mixinsEvents],
  props: {
    modal: {
      type: Object,
      default: () => {
        return { openModal: false };
      },
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    onCloseModal() {
      this.$emit("useModal");
    },
    terminarProceso() {
      this.onCloseModal();
      if (this.$store.getters.isLogin) this.$router.push("/home");
      else this.$router.push("/login");
    },
  },
};
</script>
