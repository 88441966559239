<template>
  <b-modal size="lg" centered hide-footer scrollable v-model="modal.isOpen" @close="onCloseModal()">
    <b-row>
      <b-col cols="12">
        <img src="@/assets/images/anothers/referenciasPagos.png" alt="referencias pago" class="img-fluid pb-3" />
      </b-col>
      <b-col cols="12" class="content-center">
        <b-button variant="danger" @click="onCloseModal()">Cerrar</b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  props: {
    modal: {
      type: Object,
      default: () => {
        return { isOpen: false };
      },
    },
  },
  methods: {
    onCloseModal() {
      this.$emit("useModal");
    },
  },
};
</script>
