var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-col",
    { attrs: { cols: "12" } },
    [
      _c(
        "b-form-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "b-form-group",
                [
                  _c(
                    "b-form-select",
                    {
                      model: {
                        value: _vm.credito,
                        callback: function ($$v) {
                          _vm.credito = $$v
                        },
                        expression: "credito",
                      },
                    },
                    [
                      _c(
                        "b-form-select-option-group",
                        { attrs: { label: "Número de obligación" } },
                        _vm._l(
                          _vm.$store.getters.creditosLevPrenda,
                          function (credito) {
                            return _c(
                              "b-form-select-option",
                              {
                                key: credito.numeroObligacion,
                                attrs: { value: credito },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      "Nro de Obligación " +
                                        credito.numeroObligacion +
                                        " - " +
                                        credito.estado.toUpperCase()
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: "Nombres *" } },
                [
                  _c("b-form-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.formU.nombresTitular,
                      callback: function ($$v) {
                        _vm.$set(_vm.formU, "nombresTitular", $$v)
                      },
                      expression: "formU.nombresTitular",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: "Apellidos *" } },
                [
                  _c("b-form-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.formU.apellidosTitular,
                      callback: function ($$v) {
                        _vm.$set(_vm.formU, "apellidosTitular", $$v)
                      },
                      expression: "formU.apellidosTitular",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: "Tipo de identificación *" } },
                [
                  _c("b-form-select", {
                    attrs: { options: _vm.tiposList, disabled: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "first",
                        fn: function () {
                          return [
                            _c(
                              "b-form-select-option",
                              { attrs: { value: null } },
                              [_vm._v("Seleccione")]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.formU.tipoDocumento,
                      callback: function ($$v) {
                        _vm.$set(_vm.formU, "tipoDocumento", $$v)
                      },
                      expression: "formU.tipoDocumento",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: "Número de identificación *" } },
                [
                  _c("b-form-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.formU.identificacionTitular,
                      callback: function ($$v) {
                        _vm.$set(_vm.formU, "identificacionTitular", $$v)
                      },
                      expression: "formU.identificacionTitular",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: "Celular *" } },
                [
                  _c("b-form-input", {
                    directives: [
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value: ["(###) ###-####"],
                        expression: "['(###) ###-####']",
                      },
                    ],
                    attrs: {
                      state: _vm.$v.formU.celularTitular.$dirty
                        ? !_vm.$v.formU.celularTitular.$error
                        : null,
                    },
                    model: {
                      value: _vm.$v.formU.celularTitular.$model,
                      callback: function ($$v) {
                        _vm.$set(_vm.$v.formU.celularTitular, "$model", $$v)
                      },
                      expression: "$v.formU.celularTitular.$model",
                    },
                  }),
                  _c("b-form-invalid-feedback", [
                    _vm._v(
                      _vm._s(_vm.validarCampo(_vm.$v.formU.celularTitular))
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: "Correo electrónico *" } },
                [
                  _c("b-form-input", {
                    attrs: {
                      state: _vm.$v.formU.emailTitular.$dirty
                        ? !_vm.$v.formU.emailTitular.$error
                        : null,
                    },
                    model: {
                      value: _vm.$v.formU.emailTitular.$model,
                      callback: function ($$v) {
                        _vm.$set(_vm.$v.formU.emailTitular, "$model", $$v)
                      },
                      expression: "$v.formU.emailTitular.$model",
                    },
                  }),
                  _c("b-form-invalid-feedback", [
                    _vm._v(_vm._s(_vm.validarCampo(_vm.$v.formU.emailTitular))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }