<template>
  <b-col cols="12">
    <b-form-row>
      <b-col cols="12">
        <b-form-group>
          <b-form-select v-model="credito">
            <b-form-select-option-group label="Número de obligación">
              <b-form-select-option
                :value="credito"
                :key="credito.numeroObligacion"
                v-for="credito in $store.getters.creditosLevPrenda"
              >
                {{ `Nro de Obligación ${credito.numeroObligacion} - ${credito.estado.toUpperCase()}` }}
              </b-form-select-option>
            </b-form-select-option-group>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6">
        <b-form-group label="Nombres *">
          <b-form-input v-model="formU.nombresTitular" disabled />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6">
        <b-form-group label="Apellidos *">
          <b-form-input v-model="formU.apellidosTitular" disabled />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6">
        <b-form-group label="Tipo de identificación *">
          <b-form-select :options="tiposList" v-model="formU.tipoDocumento" disabled>
            <template #first>
              <b-form-select-option :value="null">Seleccione</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6">
        <b-form-group label="Número de identificación *">
          <b-form-input v-model="formU.identificacionTitular" disabled />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6">
        <b-form-group label="Celular *">
          <b-form-input
            v-mask="['(###) ###-####']"
            v-model="$v.formU.celularTitular.$model"
            :state="$v.formU.celularTitular.$dirty ? !$v.formU.celularTitular.$error : null"
          />
          <b-form-invalid-feedback>{{ validarCampo($v.formU.celularTitular) }}</b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6">
        <b-form-group label="Correo electrónico *">
          <b-form-input
            v-model="$v.formU.emailTitular.$model"
            :state="$v.formU.emailTitular.$dirty ? !$v.formU.emailTitular.$error : null"
          />
          <b-form-invalid-feedback>{{ validarCampo($v.formU.emailTitular) }}</b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
  </b-col>
</template>

<script>
import CarteraService from "@/app/core/api/cartera";
import { required, email } from "vuelidate/lib/validators";
import mixinsEvents from "@/app/shared/mixins/mixins-events";
import AlertsService from "@/app/shared/services/alerts.service";

export default {
  mixins: [mixinsEvents],
  data() {
    return {
      credito: null,
      tiposList: this.$store.getters.listaTiposIdentificacion,
      formU: {
        nroProducto: null,
        emailTitular: null,
        tipoDocumento: null,
        celularTitular: null,
        nombresTitular: null,
        apellidosTitular: null,
        identificacionTitular: null,
      },
    };
  },
  watch: {
    formU: {
      handler: function() {
        this.$emit("updateForm", this.formU);
      },
      deep: true,
    },
    credito() {
      this.validarInfoCredito();
    },
  },
  validations: {
    formU: { emailTitular: { required, email }, celularTitular: { required } },
  },
  created() {
    this.credito = this.$store.getters?.creditosLevPrenda[0];
  },
  methods: {
    validarInfoCredito() {
      const data = this.credito;
      this.formU = {
        nroProducto: data.numeroObligacion,
        emailTitular: this.$store.getters.correoElectronico,
        identificacionTitular: this.$store.getters.identificacion,
        tipoDocumento: this.$store.getters.tipoDocumento.toString(),
        nombresTitular: `${data.primerNombre} ${data.segundoNombre}`,
        apellidosTitular: `${data.primerApellido} ${data.segundoApellido}`,
        celularTitular: this.maskCelular(this.$store.getters.numeroCelular),
      };

      const body = { cedula: this.$store.getters.identificacion, credito: data.numeroObligacion };
      CarteraService.getCreditoLevPrenda(body).then((response) => {
        if (response.data.estado) {
          if (response.data.solicitudes.length > 0) {
            let msg =
              "Te informamos que ya realizaste una solicitud de levantamiento de prenda, si deseas realizar otra solicitud puedes continuar.";
            AlertsService.info("Levantamiento de Prenda", msg);
          }
        }
      });
    },
  },
};
</script>
